<template>
    <b-modal id="modal-resources" centered :title="$t('competition.resources')" title-class="font-18" hide-footer>
        <form>
            <div class="row">

                <div class="col-xl-9 m-auto font-size-14">
                   <div v-html="rsc">
                       {{rsc}}
                   </div>

                </div>


            </div>
        </form>
    </b-modal>
</template>

<script>
    export default {
        name: "resources",
        props:['rsc']

    }
</script>

<style scoped>

</style>