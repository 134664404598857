<template>
    <b-modal id="modal-report" centered :title="$t('report.title')" ref="modal"  title-class="font-18" hide-footer>

            <div class="row">

                <div class="col-xl-11 m-auto font-size-14">
                    <form id="form"  method="POST" @submit.prevent="handleSubmit">
                    <div class="form-group mt-3 mb-0">
                        <label>{{$t('report.type')}} :</label>
                        <b-form-select size="xl" :options="types" v-model="item.type"
                                       :class="{ 'is-invalid': typesubmit && $v.item.type.$error }"></b-form-select>
                        <div v-if="typesubmit && $v.item.type.$error" class="invalid-feedback">
                            <span v-if="!$v.item.type.required"> {{$t('validation.required')}}</span>
                        </div>
                    </div>
                    <div class="form-group mt-3 mb-0">
                        <label>{{$t('report.desc')}} :</label>
                        <b-form-textarea rows="6" v-model="item.description"
                                         :class="{ 'is-invalid': typesubmit && $v.item.description.$error }"></b-form-textarea>
                        <div v-if="typesubmit && $v.item.description.$error" class="invalid-feedback">
                            <span v-if="!$v.item.description.required"> {{$t('validation.required')}}</span>
                        </div>
                    </div>
                    <input type="submit" class="btn btn-primary float-right mt-3" variant="primary" :value="$t('competition.submit')"/>
                    </form>
                </div>


            </div>

    </b-modal>
</template>

<script>
    import helper from "@/assets/helpers/helper";
    import {required} from "vuelidate/lib/validators";
    export default {
        name: "report",
        props:['challenge_id','type'],
        data(){
            return{
                typesubmit: false,
                types:[
                    {
                        value:0,
                        text:'Unintended exploits'
                    },
                    {
                        value:1,
                        text:'Broken services'
                    },
                    {
                        value:2,
                        text:'Connectivity issues'
                    },
                    {
                        value:3,
                        text:'Unintended exploits'
                    },
                    {
                        value:4,
                        text:'Flags not working'
                    },
                    {
                        value:5,
                        text:'Other'
                    },
                    ],
                item:{
                    type:'',
                    description:''
                }

            }
        },
        validations: {
            item:{
                type: {required},
                description: {required},
            },
        },
        methods:{
            handleSubmit() {
                this.typesubmit = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                this.Report();

            },
            async Report(){
                let re = false
                // t == training , c == competition
                if(this.type=='training')   re= await helper.postReturnData('challenges/'+this.challenge_id+'/reports',this.item)
                if(this.type=='competition')   re= await helper.postReturnData('competitions/'+this.$route.params.id+'/challenges/'+this.challenge_id+'/reports',this.item)
                if(re)   this.$refs['modal'].hide()

            }
        }
    }
</script>

<style scoped>

</style>