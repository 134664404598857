<template>


    <b-modal id="modal-premium" centered title="" title-class="font-18" hide-footer hide-header>
        <div class="row">
            <div class="col-xl-9 m-auto text-center ">
                <i class="bx bx-lock-alt text-danger fa-4x my-4"></i>
                <h4 class=" mb-3 text-danger"> {{$t('is_premium')}}</h4>
<!--                <p class="text-dark mb-3">You Should Subscribe to Unlock It </p>-->
            </div>
        </div>

        <div class="row">
            <div class="col-xl-12 text-center">
                <button class="btn btn-outline-danger btn-lg mb-2" @click="$router.push({ name: 'Payment' })">subscribe</button>
            </div>
        </div>




    </b-modal>
</template>

<script>
    export default {
        name: "isPremium",
        props:['title','avatar'],
        data(){
            return{

            }

        },
    }
</script>

<style scoped>
    button{
        width: 150px;
    }
    @media screen and (max-width: 900px) {
        table tr{
            display: grid;
        }

    }

</style>