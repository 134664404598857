<template>
    <b-modal id="modal-about" centered :title="about.name" title-class="font-18" hide-footer>
        <form>
            <div class="row">

                <div class="col-xl-9 m-auto font-size-14">
                   <div v-html="about.description">
                       {{about.description}}
                   </div>

                </div>


            </div>
        </form>
    </b-modal>
</template>

<script>
    export default {
        name: "aboutCategory",
        props:['about']

    }
</script>

<style scoped>

</style>