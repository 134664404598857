<template>

    <b-modal id="modal-share-social" hide-footer centered title="" title-class="font-18">
        <div class="row">
            <div class="col-xl-9 m-auto text-center ">
                <h4 class=" mb-3"> {{$t('share.congr')}}</h4>
                <i class="text-success mb-4 fas fa-check font-size-22 "></i>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-9 m-auto d-flex ">
                <h5 class="pl-lg-5 pl-sm-5 pl-md-5 pl-xl-5 m-1">{{$t('share.feedback')}}</h5>
                <div class="ml-4 star-rating mb-3 ">
                    <input type="radio" id="5-stars" name="rating" @click="rate(5)"/>
                    <label for="5-stars" class="star">&#9733;</label>
                    <input type="radio" id="4-stars" name="rating" @click="rate(4)"/>
                    <label for="4-stars" class="star">&#9733;</label>
                    <input type="radio" id="3-stars" name="rating" @click="rate(3)"/>
                    <label for="3-stars" class="star">&#9733;</label>
                    <input type="radio" id="2-stars" name="rating" @click="rate(2)"/>
                    <label for="2-stars" class="star">&#9733;</label>
                    <input type="radio" id="1-star" name="rating"  @click="rate(1)"/>
                    <label for="1-star" class="star">&#9733;</label>
                </div>

            </div>

        </div>
        <div class="row" v-if="t">
            <div class="col-xl-12 m-auto" >
                <h5 class="text-center mb-2">{{$t('share.with')}}</h5>
                <div class="m-auto text-center">
                    <ShareNetwork class="btn mr-3 mb-2 text-white"
                                  v-for="network in networks"
                                  :network="network.network"
                                  :key="network.network"
                                  :style="{backgroundColor: network.color}"
                                  :url="sharing.url"
                                  :title="sharing.title"
                                  :description="sharing.description"
                                  :quote="sharing.quote"
                                  :hashtags="sharing.hashtags"
                                  :twitterUser="sharing.twitterUser"
                    >
                        <i :class="network.icon"></i>
                        <span class="ml-3">{{ network.name }}</span>
                    </ShareNetwork>
                </div>
        </div>

        </div>




    </b-modal>

</template>

<script>

    import helper from "@/assets/helpers/helper";

    export default {
        name: "shareSocialMedia",
        props:['t','challenge','category'],
        data(){
            return{
                sharing: {
                    url: this.$parent_stream+'/training?challenge='+this.challenge,
                    title: 'I Solved The '+this.challenge.name+' Challenge in Category '+this.category,
                    description:'I Solved The '+this.challenge.name+' Challenge in Category '+this.category,
                    quote: 'I Solved The '+this.challenge.name+' Challenge in Category '+this.category,
                    hashtags: 'CRISI5',
                    twitterUser: 'CRISI5'
                },
                networks: [
                    { network: 'facebook', name: 'Facebook', icon: 'fab fah fa-lg fa-facebook-f', color: '#1877f2' },
                    { network: 'linkedin', name: 'LinkedIn', icon: 'fab fah fa-lg fa-linkedin', color: '#007bb5' },
                    { network: 'twitter', name: 'Twitter', icon: 'fab fah fa-lg fa-twitter', color: '#1da1f2' },
                ]
            }

        },
        methods:{
            async rate(rating){
                let link = 'challenges/'+this.challenge.id+'/ratings'
                if(!this.t) link = 'competitions/'+this.$route.params.id+'/challenges/'+this.challenge.id+'/ratings'
                let re =  await helper.postData(link,{'rating':rating},'s')
                if(re) this.$emit('refresh')

            }
        }

    }
</script>

<style scoped>
    button{
        width: 150px;
    }
    /* component */

    .star-rating {
        display:flex;
        flex-direction: row-reverse;
        font-size:1.5em;
        justify-content:space-around;
        padding:0 .2em;
        text-align:center;
        width:5em;
    }

    .star-rating input {
        display:none;
    }

    .star-rating label {
        color:#ccc;
        cursor:pointer;
    }

    .star-rating :checked ~ label {
        color:#f90;
    }

    .star-rating label:hover,
    .star-rating label:hover ~ label {
        color:#fc0;
    }
    @media screen and (max-width: 900px) {
      table tr{
          display: grid;
      }

    }


</style>
