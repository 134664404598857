const Web3 = require("web3");//import web3
import Vue from 'vue'
const p = new Web3.providers.HttpProvider("https://api.avax-test.network/ext/bc/C/rpc")
const Web3Client = new Web3(p);
const challengeFactory = '0x477c6fd9bB06A657fD6A8eD6f93c6fC2B878dbC1'
const factoryABI = [
    {
        constant: true,
        inputs: [{internalType: "string", name: "", type: "string"}],
        name: "createChallengeInstance",
        outputs: [{internalType: "address", name: "", type: "address"}],
        payable: false,
        stateMutability: "external",
        type: "function"
    },
    {
        constant: true,
        inputs: [{internalType: "address", name: "", type: "address"}, {
            internalType: "string",
            name: "",
            type: "string"
        }],
        name: "getChallengeInstance",
        outputs: [{internalType: "address", name: "", type: "address"}],
        payable: false,
        stateMutability: "public",
        type: "function"
    }

]
const AVAX_PARAMS = {
    chainId: '0xa869',
    chainName: 'Avalanche Fuji C-Chain',
    nativeCurrency: {
        symbol: 'AVAX',
        decimals: 18
    },
    rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://testnet.snowtrace.io/']
}
// const challengeABI = [

// ]
const factoryContract = new Web3Client.eth.Contract(factoryABI, challengeFactory);

const Web3Helper = {
    async loginMetamask() {
        try {
            let accounts = await window.ethereum.request({method: 'eth_requestAccounts'});
            Web3Client.eth.defaultAccount = accounts[0]
            await this.addNetwork()
            return accounts[0]
        } catch (e) {
            console.log(e)
            if(!window.ethereum)   Vue.prototype.$alertify.error("looks like you don't have a wallet yet ");
             else Vue.prototype.$alertify.error(e.message);
            return false
        }
    },
    async signMetamask() {
        await this.loginMetamask()
        try {
            let a = await window.ethereum.request({
                method: 'personal_sign',//personal_sign
                params: ['Welcome to CRISIS', Web3Client.eth.defaultAccount],
                from: Web3Client.eth.defaultAccount
            })
            console.log("signature : ", a)
            return a;
        } catch (e) {
            Vue.prototype.$alertify.error(e.message);
        }
    },
    async addNetwork() {
        try {
            // check if the chain to connect to is installed
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{chainId: '0xa869'}], // chainId must be in hexadecimal numbers
            });
        } catch (error) {
            // This error code indicates that the chain has not been added to MetaMask
            // if it is not, then install it into the user MetaMask
            if (error.code === 4902) {
                try {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [AVAX_PARAMS],
                    });
                } catch (addError) {
                    console.error(addError);
                }
            }
            console.error(error);
        }
    },
    async getChallengeInstance(challenge_id) {
        await this.loginMetamask()
        console.log('-----get challenge instance -------')
        let instance = await factoryContract.methods.getChallengeInstance(Web3Client.eth.defaultAccount, challenge_id).call()
        console.log(instance)
		// const contract = new Web3Client.eth.Contract(challengeABI, instance);
		// window.contract = contract;
        return instance
    },

    async NewChallengeInstance(challenge_id) {
        await this.loginMetamask()
        console.log('----- NewChallengeInstance -------')
        let address = Web3Client.eth.defaultAccount
        let data = await factoryContract.methods.createChallengeInstance(challenge_id);
        var count = await Web3Client.eth.getTransactionCount(address);
        var rawTx = {
            from: address,
            // gasPrice: Web3Client.utils.toHex(5000000000),
            // gas: Web3Client.utils.toHex(1700000),
            to: challengeFactory,
            data: data.encodeABI(),
            nonce: Web3Client.utils.toHex(count + 1),
            chainId: '0xa869',
        };
        Web3Client.eth.defaultAccount = address
        return await window.ethereum.request({
            method: 'eth_sendTransaction',
            params: [rawTx]
        })
    },
    async getReceiptTx(tx) {
        return await Web3Client.eth.getTransactionReceipt(tx)

    },
    decodeLogs(rec) {
        return Web3Client.eth.abi.decodeParameters([{
                type: 'address',
                name: 'requestor'
            }, {type: 'string', name: 'challengeId'}, {
                type: 'address',
                name: 'challengeInstanceAddress'
            }],
            rec.logs[0].data)
    },


}
export {Web3Helper}