<template>
    <b-modal id="modal-stream" ref="modal"   title-class="font-18" hide-footer @shown="create">
      <template #modal-header>
        <!-- Emulate built in modal header close button action -->

        <h5>{{$t('profil_user.settings.streaming')}}</h5>

        <span class="badge badge-pill  font-size-12 badge-soft-primary p-2 " v-if="streaming.state == 'approved'">{{$t('profil_user.settings.approved')}}</span>
        <span class="badge badge-pill  font-size-12 badge-soft-warning p-2 " v-if="streaming.state == 'pending'">{{$t('profil_user.settings.pending')}}</span>
        <span class="badge badge-pill  font-size-12 badge-soft-danger p-2 " v-if="streaming.state == 'not_approved'">{{$t('profil_user.settings.not_approved')}}</span>

      </template>
        <form>
            <div class="row">

                <div class="col-xl-10 m-auto font-size-14">
                    <div class="form-group my-3 mb-0">
                        <label>{{$t('profil_user.settings.twitch_user')}} :</label>
                        <b-form-input type="text" v-model="streaming.username_twitch" ></b-form-input>
                        <div v-if="!streaming.username_twitch && is_Loaded" class="text-danger mt-3">{{$t('profil_user.settings.stream_error')}}</div>

                    </div>

                     <input class="btn btn-primary w-100 mt-3" v-if="!streaming.username_twitch && is_Loaded" :value="$t('profil_user.settings.info')" type="button" @click="addStreamingInfo"/>
                </div>


            </div>
        </form>
    </b-modal>
</template>

<script>
    import helper from '@/assets/helpers/helper';

    export default {
        name: "newStream",
        data(){
            return{

                streaming: {},
                is_Loaded:false
            }
        },

        methods: {
           async create(){
                this.is_Loaded = false
                this.streaming= await helper.getAll('me/streaming')
                this.is_Loaded = true
            },

            async addStreamingInfo()
            {

            this.$http
                    .post('streamings', {"username_twitch":this.streaming.username_twitch})
                    .then(response => {
                        this.$router.push({name:'MyProfil', query: { streaming: '1',s:'1'}});
                        console.log(response);
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },

            // startStream(){

            // },

        },
    }
</script>

<style scoped>

</style>